<template>
  <div>
    <common-dialog
      :show="show"
      :width="800"
      @close="closeDialog"
    >
      <template #body>
        <span>
          Объединять ли разные наименования в один товар?
        </span>
      </template>
      <template #footer>
        <div class="d-flex justify-end mt-4">
          <v-btn
            dark
            color="red"
            elevation="0"
            @click="grouppingGoods(true)"
          >
            Да
          </v-btn>
          <v-btn
            dark
            color="teal lighten-2"
            elevation="0"
            @click="grouppingGoods(false)"
          >
            Нет
          </v-btn>
        </div>
      </template>
    </common-dialog>
  </div>
</template>
<script>


import CommonDialog from "@/components/shared/common-dialog.vue";

export default {
  components: {CommonDialog},
  data() {
    return {
      show: false
    }
  },
  methods: {
    showDialog(){
      this.show = true
    },
    openUserDialog() {      
      this.showDialog()        
    },
    closeDialog() {
      this.show = false
    },
    grouppingGoods(joinArticleDescription) {
      const msg = "Товары сгруппированы"
      return this.$store.dispatch('dt/groupGoods', {joinArticleDescription})
        .then(() => this.$success(msg))
        .then(() => this.closeDialog())
        .catch(() => this.$error())
    },

  }
}
</script>
