<template>
  <div
    class="statistics-box statistics-transport-list ma-3"
    :class="{
      'blocked-box':disabledView
    }"
  >
    <v-container
      fluid
      class="pb-4"
    >
      <v-row
        :id="block_id"
        v-click-outside="triggerOnFocus"
        class="mb-0"
      >
        <v-col
          cols="12"
          class="d-flex align-end"
        >
          <span
            class="font-weight-bold"
          >Условия перевозки и транспорт</span>
        </v-col>
        <v-col
          class="pr-0"
          cols="2"
        >
          <label>
            15  Страна отправления
            <v-autocomplete
              ref="dispatch_country_letter"
              v-model="about_transport.dispatch_country_letter"
              auto-select-first
              outlined
              dense
              hide-details="auto"
              background-color="white"
              item-text="text"
              item-value="letterCode"
              :items="countries"
              :filter="filterBySearchField"
              @change="setDispatchRegion"
            />
          </label>
        </v-col>
        <v-col cols="1">
          <label>Регион</label>
          <v-autocomplete
            ref="dispatch_country_region"
            v-model="about_transport.dispatch_country_region"
            auto-select-first
            :items="regionsForDispatchCountry"
            item-text="text"
            item-value="code"
            :filter="filterBySearchField"
            outlined
            dense
            background-color="white"
            hide-details="auto"
          />
        </v-col>
        <v-col
          cols="2"
          class="pr-0"
        >
          <label>
            17  Страна назначения
            <v-autocomplete
              ref="destination_country_letter"
              v-model="about_transport.destination_country_letter"
              auto-select-first
              outlined
              dense
              :disabled="isImport"
              hide-details="auto"
              :background-color="isImport ? 'grey lighten-2': 'white'"
              item-text="text"
              item-value="letterCode"
              :items="countries"
              :filter="filterBySearchField"
              @change="setDestinationRegion"
            />
          </label>
        </v-col>
        <v-col cols="1">
          <label>Регион</label>
          <v-autocomplete
            ref="destination_country_region"
            v-model="about_transport.destination_country_region"
            auto-select-first
            :disabled="isImport"
            :items="regionsForDestinationCountry"
            item-text="text"
            item-value="code"
            :filter="filterBySearchField"
            outlined
            dense
            :background-color="isImport ? 'grey lighten-2': 'white'"
            hide-details="auto"
          />
        </v-col>
        <v-col
          cols="4"
        >
          <label>
            20 Условия поставки
            <v-menu offset-x>
              <template #activator="{ on }">
                <v-icon
                  tabindex="-1"
                  dense
                  class="mx-1"
                  v-on="on"
                >
                  mdi-tune
                </v-icon>
              </template>
              <v-list>
                <v-list-item @click="openIncoterms">
                  Поиск в справочнике
                </v-list-item>
                <v-list-item @click="makeDifferent">
                  Разные
                </v-list-item>
              </v-list>
            </v-menu>
          </label>
          <div class="d-flex">
            <v-autocomplete
              ref="delivery_terms_code"
              v-model="about_transport.delivery_terms_code"
              :items="deliveryTerms"
              style="width:30%"
              class="mr-2"
              auto-select-first
              outlined
              dense
              hide-details="auto"
              background-color="white"
              item-text="code"
              item-value="code"
            />
            <v-text-field
              ref="delivery_place"
              v-model="about_transport.delivery_place"
              style="width:70%;"
              outlined
              dense
              class="mr-2"
              hide-details="auto"
              background-color="white"
            />
            <v-autocomplete
              ref="delivery_type_code"
              v-model="about_transport.delivery_type_code"
              auto-select-first
              outlined
              style="width:100px;"
              class="mr-1"
              :disabled="disabledDelivery"
              :items="delivery"
              :background-color="disabledDelivery ? 'grey lighten-2' : 'white'"
              dense
              item-text="code"
              item-value="code"
              hide-details="auto"
            />
            <v-tooltip
              bottom
              :disabled="!about_transport.delivery_type_code"
              max-width="300"
            >
              <template #activator="{ on, attrs }">
                <v-btn
                  class="my-auto"
                  text
                  max-width="40px"
                  :disabled="disabledDelivery"
                  min-width="40px"
                  min-height="40px"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-information</v-icon>
                </v-btn>
              </template>
              <span>{{ deliveryName }}</span>
            </v-tooltip>
          </div>
        </v-col>
        <v-col class="pl-0">
          <div class="d-flex">
            <div>
              <label>
                19  Контейнер
              </label>
              <v-autocomplete
                ref="container_indicator"
                v-model="about_transport.container_indicator"
                item-text="text"
                item-value="value"
                class="mr-2"
                :items="container_types"
                auto-select-first
                background-color="white"
                dense
                outlined
                hide-details="true"
              />
            </div>
            <div>
              <label style="white-space:nowrap;">
                25 ТС на границе
                <v-autocomplete
                  ref="border_transport_type"
                  v-model="about_transport.border_transport_type"
                  item-text="text"
                  item-value="code"
                  :items="transport"
                  class="mr-2"
                  auto-select-first
                  background-color="white"
                  dense
                  outlined
                  hide-details="true"
                  :filter="filterByCode"
                />
              </label>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row
        v-show="selected?.type_declaration?.declaration_kind_code === 'ЭК'"
        class="mt-0"
      >
        <v-col
          v-if="!transports_json.length"
          ref="transports_json"
          cols="6"
        >
          <v-btn
            class="elevation-0 mt-7"
            color="transparent"
            @click="addTransport"
          >
            Добавить транспорт в графы 18 и 21 <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-col>
        <v-col
          v-else
          ref="transports_json"
          cols="6"
        >
          <div
            class="d-flex px-1"
          >
            <div class="header__code">
              <small>Номер ТС</small>
            </div>
            <div 
              v-if="!xsdUpdate"
              class="header__summ"
            >
              <small>VIN</small>
            </div>
            <div
              v-if="!xsdUpdate"
              class="header__currency"
            >
              <small>Страна рег.</small>
            </div>
            <div
              v-if="xsdUpdate"
              class="header__type"
            >
              <small>Код вида ТС</small>
            </div>
            <div
              class="header__code_sp"
            >
              <small>Гр. 18</small>
            </div>
            <div
              class="header__code_sp"
            >
              <small>Гр. 21</small>
            </div>
            <div class="header__action" />
          </div>
          <transport-item
            v-for="(item, idx) in transports_json"
            :key="idx"
            :item="item"
            :index="idx"
            :amount="transports_json.length"
            :base="about_transport"
            :shell="destination_customs_json"
            :all="transports_json"
            :countries="countries"
            :vehicle-types="vehicleTypes"
            :control="transportControlPath"
            :xsd-update="xsdUpdate"
            @addTransport="addTransport"
            @deleteTransport="deleteTransport"
          />
        </v-col>
        <v-col
          v-if="!destination_customs_json.length"
          cols="4"
        >
          <v-btn
            class="elevation-0 mt-7"
            color="transparent"
            @click="addDestination"
          >
            29 Добавить орган въезда/выезда <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-col>
        <v-col
          v-else
          ref="destination_customs_json"
          cols="4"
        >
          <destination-item
            v-for="(item, idx) in destination_customs_json"
            :key="idx"
            :item="item"
            :index="idx"
            :amount="destination_customs_json.length"
            :base="about_transport"
            :shell="transports_json"
            :all="destination_customs_json"
            :customs-points="customsPoints"
            @addDestination="addDestination"
            @deleteDestination="deleteDestination"
          />
        </v-col>
        <v-col cols="2">
          <div
            class="ml-auto"
            style="width:52%;"
          >
            <label>
              26 ТС вн. стр.
              <v-autocomplete
                ref="arrival_transport_type"
                v-model="about_transport.arrival_transport_type"
                item-text="text"
                item-value="code"
                class="ml-auto"
                :items="transport"
                auto-select-first
                :background-color="selected?.type_declaration?.declaration_kind_code === 'ИМ' ? 'grey lighten-2' : 'white'"
                :disabled="selected?.type_declaration?.declaration_kind_code === 'ИМ'"
                dense
                outlined
                hide-details="true"
                :filter="filterByCode"
              />
            </label>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import TransportItem from './transport-item.vue'
import DestinationItem from './destination-item.vue'
import cloneDeep from 'lodash.clonedeep'
import blockAutoUpdate from "@/mixins/block-auto-update.mixin"
import { filterBySearchFieldMixin } from "@/mixins/catalogs"
import {getCountryDigitCodeByLetterCode, getCountryNameByCode} from "@/helpers/catalogs"
import { eventBus } from "@/bus.js"
import {about_transport as onAboutTransport, transports as onTransports} from "@/events/statistics/control";
import {
  getScrollOffset,
  highlightField, isDestinationCustomsError, isDestinationCustomsCommonError,
  isTransportCommonError,
  isTransportError,
  removeFirstFromMap
} from "@/helpers/control";
import { showCatalog, catalogValueSelected } from "@/events/statistics";
const TRANSPORT_LIST_CLASS = ".statistics-transport-list"
export default {
  components: {
    TransportItem,
    DestinationItem
  },
  mixins: [blockAutoUpdate, filterBySearchFieldMixin],
  data() {
    return {
      block_id: 'dt-about-transport',
      about_transport: {
        declaration_id: null,
        arrival_transport_type: '',
        border_transport_type: '',
        container_indicator: '',
        delivery_place: '',
        delivery_terms_code: '',
        delivery_type_code: '',
        destination_country_letter: '',
        destination_country_name: '',
        destination_country_region:"",
        dispatch_country_letter: '',
        dispatch_country_name: '',
        dispatch_country_region:"",
      },
      transports_json: [],
      destination_customs_json: [],
      container_types: [
        {
          text: '0 - без контейнера',
          value: false,
        },
        {
          text: '1 - с контейнером',
          value: true,
        },
      ],
      disabled: true,
      disabledDelivery: true,
      transportControlPath: {
        path: null
      },
      xsdUpdate: null
    }
  },
  computed: {
    ...mapGetters({
      selected: "dt/getSelected",
      countries: "catalogs/getNsiCountries",
      deliveryTerms: "catalogs/getNsiDeliveryTerms",
      vehicleTypes: "catalogs/getNsiVehicleTypes",
      transport: "catalogs/getNsiTransportTypes",
      delivery: "catalogs/getNsiTypesOfDelivery",
      features: "catalogs/getNsiDealFeaturesDT",
      customsPoints: 'catalogs/getNsiCustomsPoints',
      regions:'catalogs/getNsiRegions',
      disabledView:"dt/getVisibility",
      isImport:"dt/isImport",
      xsdVersion: "catalogs/getXsdVersion"
    }),
    regionsForDispatchCountry(){
      if(this.about_transport.dispatch_country_letter){
        const code = getCountryDigitCodeByLetterCode(this.countries, this.about_transport.dispatch_country_letter)
        return this.regions.filter(i => i.countryDigit === code || i.countryDigit === null)
      }
      return this.regions
    },
    regionsForDestinationCountry(){
      if(this.about_transport.destination_country_letter){
        const code = getCountryDigitCodeByLetterCode(this.countries,this.about_transport.destination_country_letter)
        return this.regions.filter(i => i.countryDigit === code || i.countryDigit === null)
      }
      return this.regions
    },
    deliveryName(){
      const {name} = this.delivery.find(i => i.code === this.about_transport.delivery_type_code) || {}
      return name || "";
    },
  },
  watch: {
    selected: {
      handler() {
        this.disabledDelivery = this.selected?.type_declaration.declaration_kind_code !== 'ЭК';
        this.setFields()
      },
      deep: true,
      immediate: false
    },
  },
  created(){
    onAboutTransport.subscribe(this.onAboutTransportControl);
    onTransports.subscribe(this.onTransportControl)
    eventBus.$on("clearTransports", () => {
      this.clearTransports()
    })
    catalogValueSelected.subscribe(this.updatesResolver, [
      'about_transport.delivery_terms_code'
    ])
  },
  beforeDestroy() {
    onAboutTransport.unsubscribe();
  },
  methods: {
    updatesResolver({code, field, catalog = null}){
      if(code && field){
        if(field === 'about_transport.delivery_terms_code'){
          this.about_transport[catalog] = code
        }
        this.fieldsBeenVisitedByUser()
        this.triggerOnChange()
      }
    },
    openIncoterms() {
      showCatalog.trigger({
        type: "incoterms",
        search:"",
        field: "about_transport.delivery_terms_code",
      });
    },
    makeDifferent(){
      this.about_transport.delivery_terms_code = ''
      this.about_transport.delivery_place = 'РАЗНЫЕ'
      this.about_transport.delivery_type_code = ''
      this.readyToUpdate()
    },
    readyToUpdate(){
      this.hasChanges = true,
      this.fields_been_visited = true
    },
    setDispatchRegion(countryLetterCode){
      this.about_transport.dispatch_country_region = this.getAvailableRegion(countryLetterCode)
    },
    setDestinationRegion(countryLetterCode){
      this.about_transport.destination_country_region = this.getAvailableRegion(countryLetterCode)
    },
    getAvailableRegion(countryLetterCode){
      return !['RU', 'UA', 'KZ'].includes(countryLetterCode) ? "000" : null
    },
    onAboutTransportControl(pathObject){
      if(isTransportError(pathObject)){
        removeFirstFromMap(pathObject.path)
        return this.onTransportControl(pathObject)
      }
      if(isDestinationCustomsError(pathObject)){
        removeFirstFromMap(pathObject.path)
        return this.onDestinationCustomsControl(pathObject)
      }
      highlightField.call(this, pathObject)
    },
    onDestinationCustomsControl(pathObject){
      if(isDestinationCustomsCommonError(pathObject)){
        const docs = document.querySelector(TRANSPORT_LIST_CLASS);
        const offset = getScrollOffset();
        this.$scrollTo(docs, 200, {
          force: true,
          offset
        });
      }
    },
    onTransportControl(pathObject){
      if(isTransportCommonError(pathObject)){
        highlightField.call(this, pathObject)
      }else {
        const docs = document.querySelector(TRANSPORT_LIST_CLASS);
        const offset = getScrollOffset();
        this.$scrollTo(docs, 200, {
          force: true,
          offset,
          onDone: () => {
            this.transportControlPath = pathObject
          },
        });
      }
    },
    clearTransports(){
      this.transports_json = []
      this.destination_customs_json = []
      this.about_transport.arrival_transport_type = null
      this.uploadData()
    },
    addDestination(){
      this.destination_customs_json.push({
        customs_code: null,
        customs_name: null
      })
      this.uploadData()
    },
    deleteDestination(i){
      this.destination_customs_json.splice(i, 1)
      this.uploadData()
    },
    addTransport(){
      this.transports_json.push({
        number: null,
        country_letter: null,
        country_name: null,
        vehicle_type: null,
        graph_18: null,
        graph_21: null,
        vin: null
      })
      this.uploadData()
    },
    deleteTransport(i){
      this.transports_json.splice(i, 1)
      this.uploadData()
    },
    setFields(){
      this.disabled = !this.selected?.type_declaration.declaration_kind_code === 'ЭК'

      const about_transport = this.selected.about_transport
      const transports_json = about_transport?.transports_json ?? []
      const destination_customs_json = about_transport?.destination_customs_json ?? []

      this.transports_json = cloneDeep(transports_json)
      this.destination_customs_json = cloneDeep(destination_customs_json)

      Object.keys(this.about_transport).forEach(key => {
        if(key in about_transport){
          this.about_transport[key] = about_transport[key]
        }
      })

      this.xsdUpdate = this.checkXsdDateUpdate()
      if (this.transports_json.length > 0) {
        this.cleanFieldsByXsdUpdateDate(this.xsdUpdate)
      }
    },
    uploadData(){
      const about_transport = this.convertEmptyStringsToNull({
        ...this.about_transport,
        destination_country_name: getCountryNameByCode(this.countries, this.about_transport.destination_country_letter) || null,
        dispatch_country_name: getCountryNameByCode(this.countries, this.about_transport.dispatch_country_letter) || null,
        transports_json: this.transports_json,
        destination_customs_json: this.destination_customs_json
      })

      return this.$store.dispatch("dt/uploadBlockData", {
        name: "about_transport",
        value: about_transport,
      })
    },
    filterByCode(item, queryText) {
      return (
        item.code.indexOf(queryText.toLocaleLowerCase()) >
          -1
      );
    },
    checkXsdDateUpdate() {
      const xsdUpdateDateList = this.xsdVersion.filter(item => {
        return (item.declarationType.toUpperCase() === "DT")
      }) 
      const xsdUpdateDate = xsdUpdateDateList.length == 0 ? null : xsdUpdateDateList[0].dateUpdate

      return !(xsdUpdateDate == null || (xsdUpdateDate != null && this.selected.about_declaration.declaration_date < xsdUpdateDate))
    },
    cleanFieldsByXsdUpdateDate(xsdUpdate) {
      let startUpdate = false      
      this.transports_json.forEach(transport => {
        if (!xsdUpdate) {
          if (transport.vehicle_type != null ) {
            transport.vehicle_type = null
            startUpdate = true
          }
        }
        else {
          if (transport.country_name != null || transport.country_letter != null || transport.vin != null) {
            transport.country_name = null
            transport.country_letter = null
            transport.vin = null 
            startUpdate = true
          }       
        }  
        
        if (startUpdate) this.readyToUpdate()
      })
    }
  }
}
</script>

<style scoped>
.header__code{
   width: 120px !important;
   margin: 0 10px 0 0;
}
.header__summ{
   width: 190px !important;
   margin: 0 10px 0 0;
}
.header__currency{
    width: 120px !important;
    margin: 0 10px 0 0;
}
.header__code_sp{
   width: 60px !important;
   margin: 0 10px 0 0;
}
.header__action{
   /* width: 160px !important; */
   /* padding: 0 10px 0 0; */
}
.header__type{
  width: 190px !important;
  margin: 0 10px 0 0;
}
.document-add {
  position: absolute;
  top:50%;
  right: 50%;
  transform: translateX(50%);
  z-index: 1;
}
</style>
